import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          ab<b>cardiogram</b>
        </p>
        <p>coming soon</p>
      </header>
    </div>
  );
}

export default App;
